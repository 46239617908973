import { rangeObj } from 'src/components/date_picker/dates';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import {
  format,
  startOfDay,
  subDays,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  subMonths,
  startOfYear,
  endOfWeek,
  endOfYear,
} from 'date-fns';

const rangeObject = rangeObj();

const initialState = {
  order: rangeObject.thismonth,
  inbound: rangeObject.thismonth,
  outbound: rangeObject.today,
  picking: rangeObject.today,
  shippingContainer: rangeObject.thismonth,
  purchaseOrder: rangeObject.thismonth,
  dashboard: rangeObject.today,
  inventoryTransfer: rangeObject.thismonth,
};

const store = (set, get) => ({
  ...initialState,
  setDate: (page, date) => {
    set((prev) => ({ ...prev, [page]: date }));
  },
  getDate: (page) => {
    var temp = get()[page];

    if (temp.alias !== 'custom') {
      temp = checkDateValidity(temp);
    }
    temp.period.until = new Date(temp.period.until);
    temp.period.since = new Date(temp.period.since);
    return temp;
  },
  reset: () => {
    set(initialState);
  },
});

const checkDateValidity = (temp) => {
  let dateToday = startOfDay(new Date());
  const type = temp.alias;
  const formatDate = (date) => format(date, 'yyyyMMdd');
  if (type === 'today') {
    if (temp.fromDt !== formatDate(dateToday)) {
      const todayDate = formatDate(dateToday);
      const dateToSend = {
        ...temp,
        fromDt: todayDate,
        toDt: todayDate,
        period: {
          until: new Date(dateToday),
          since: new Date(dateToday),
        },
      };
      return dateToSend;
    } else {
      return temp;
    }
  } else if (type === 'yesterday') {
    const yesterday = subDays(dateToday, 1);
    if (temp.fromDt !== formatDate(yesterday)) {
      const yesterdayDate = formatDate(yesterday);
      const dateToSend = {
        ...temp,
        fromDt: yesterdayDate,
        toDt: yesterdayDate,
        period: {
          until: new Date(yesterday),
          since: new Date(yesterday),
        },
      };
      return dateToSend;
    } else {
      return temp;
    }
  } else if (type === 'thisweek') {
    const thisweek = startOfWeek(dateToday, { weekStartsOn: 0 });
    if (temp.fromDt !== formatDate(thisweek)) {
      const thisweekDate = formatDate(thisweek);
      const endCurrentWeek = endOfWeek(dateToday, { weekStartsOn: 0 });
      const endOfCurrentWeek = formatDate(endCurrentWeek);
      const dateToSend = {
        ...temp,
        fromDt: thisweekDate,
        toDt: endOfCurrentWeek,
        period: {
          since: new Date(thisweek),
          until: new Date(endCurrentWeek),
        },
      };
      return dateToSend;
    } else {
      return temp;
    }
  } else if (type === 'thismonth') {
    const start = startOfMonth(dateToday);
    if (temp.fromDt !== formatDate(start)) {
      const thisMonthDate = formatDate(start);
      const end = endOfMonth(dateToday);
      const endThisMonthDate = formatDate(end);
      const dateToSend = {
        ...temp,
        fromDt: thisMonthDate,
        toDt: endThisMonthDate,
        period: {
          since: new Date(start),
          until: new Date(end),
        },
      };
      return dateToSend;
    } else {
      return temp;
    }
  } else if (type === 'lastmonth') {
    const start = startOfMonth(subMonths(dateToday, 1));
    if (temp.fromDt !== formatDate(start)) {
      const lastMonthDate = formatDate(start);
      const end = endOfMonth(subMonths(dateToday, 1));
      const endlastMonthDate = formatDate(end);
      const dateToSend = {
        ...temp,
        fromDt: lastMonthDate,
        toDt: endlastMonthDate,
        period: {
          since: new Date(start),
          until: new Date(end),
        },
      };
      return dateToSend;
    } else {
      return temp;
    }
  } else if (type === 'past3months') {
    const start = startOfMonth(subMonths(dateToday, 2));

    if (temp.fromDt !== formatDate(start)) {
      const threeMonthDate = formatDate(start);
      const end = endOfMonth(subMonths(dateToday, 0));

      const endThreeMonthDate = formatDate(end);
      const dateToSend = {
        ...temp,
        fromDt: threeMonthDate,
        toDt: endThreeMonthDate,
        period: {
          since: new Date(start),
          until: new Date(end),
        },
      };
      return dateToSend;
    } else {
      return temp;
    }
  } else if (type === 'past6months') {
    const start = startOfMonth(subMonths(dateToday, 5));

    if (temp.fromDt !== formatDate(start)) {
      const sixMonthDate = formatDate(start);
      const end = endOfMonth(subMonths(dateToday, 0));
      const endSixMonthDate = formatDate(end);
      const dateToSend = {
        ...temp,
        fromDt: sixMonthDate,
        toDt: endSixMonthDate,
        period: {
          since: new Date(start),
          until: new Date(end),
        },
      };
      return dateToSend;
    } else {
      return temp;
    }
  } else if (type === 'thisyear') {
    const start = startOfYear(dateToday);
    if (temp.fromDt !== formatDate(start)) {
      const startYearDate = formatDate(start);
      const end = endOfYear(dateToday);
      const endYearDate = formatDate(end);
      const dateToSend = {
        ...temp,
        fromDt: startYearDate,
        toDt: endYearDate,
        period: {
          since: new Date(start),
          until: new Date(end),
        },
      };
      return dateToSend;
    } else {
      return temp;
    }
  } else if (type === 'lastyear') {
    const start = startOfYear(subMonths(dateToday, 12));
    if (temp.fromDt !== formatDate(start)) {
      const lastYearDate = formatDate(start);
      const end = endOfYear(subMonths(dateToday, 12));
      const endLastYearDate = formatDate(end);
      const dateToSend = {
        ...temp,
        fromDt: lastYearDate,
        toDt: endLastYearDate,
        period: {
          since: new Date(start),
          until: new Date(end),
        },
      };
      return dateToSend;
    } else {
      return temp;
    }
  }
};

const dateStore = create(
  persist(store, {
    name: 'date',
    storage: createJSONStorage(() => localStorage),
  }),
);

export default dateStore;
