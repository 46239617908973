import { Icon } from '@shopify/polaris';
import { Box, ListItemButton, Typography } from '@mui/material';
import { ChevronDownIcon, ChevronRightIcon } from '@shopify/polaris-icons';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import Link from 'src/components/link/link';
import { useHover } from 'src/hooks/use_hover';
import { isEmpty } from 'src/utils/type_check';

// ----------------------------------------------------------------------

export default function NavItem({ item, open, depth, active, onClick, child, ...other }) {
  const { label, url, icon, selectedIcon, children, onClick: onItemClick } = item;
  const [ref, isHover] = useHover();
  const isSetting = item?.label === 'Setting';
  const subItem = depth !== 0;
  const renderContent = (
    <ListItemButton
      ref={ref}
      disableGutters
      sx={{
        backgroundColor: active ? 'var(--p-color-nav-bg-surface-selected)' : 'inherit',
        borderRadius: 'var(--p-border-radius-200)',
        p: 'var(--p-space-200)',
        display: 'flex',
        justifyContent: 'space-between',
        mx: '12px',
      }}
      onClick={() => {
        onClick();
      }}
      {...other}
    >
      <Box
        display="flex"
        justifyContent={'space-between'}
        width={'100%'}
        height={'1.2rem'}
      >
        <Box
          display="flex"
          alignItems={'center'}
        >
          {selectedIcon && icon && !subItem && (
            <Box sx={{ width: '20px' }}>
              <Icon source={active ? selectedIcon : icon} />
            </Box>
          )}

          {subItem && (
            <Box sx={{ width: '20px', pl: 1 }}>
              <SubdirectoryArrowRightIcon
                sx={{
                  fontSize: '1rem',
                  display: active ? 'box' : isHover ? 'box' : 'none',
                }}
                color={active ? 'primary' : 'disabled'}
              />
            </Box>
          )}
          <Typography
            sx={{
              ml: 1,
            }}
            variant={
              child
                ? active
                  ? 'body_sm_semibold'
                  : 'body_sm'
                : active
                  ? 'body_md_semibold'
                  : 'body_md'
            }
            color={!active && subItem ? 'text.secondary' : null}
          >
            {label}
          </Typography>
        </Box>
        {!isEmpty(children) && (
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'flex-end'}
          >
            {!isSetting && <Icon source={open ? ChevronDownIcon : ChevronRightIcon} />}
          </Box>
        )}
      </Box>
    </ListItemButton>
  );

  // Has child
  if (children || onItemClick) {
    return renderContent;
  }

  // Default
  return (
    <Link
      href={url}
      color="inherit"
    >
      {renderContent}
    </Link>
  );
}
